import { TextareaAutosize, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

function FeedbackDetailsList(props) {
  return (
    <>
      <label className="questionLabel">
        {props.index}) {props.question.questionDescription}
      </label>
      {props.question.questionType === 'text' ? (
        <TextareaAutosize
          aria-label="minimum height"
          className="noResize"
          disabled={true}
          value={props.question?.questionValue}
          minRows={3}
          maxRows={3}
        />
      ) : (
        <FormControl disabled={true}>
          <RadioGroup
            className="radioGroup"
            aria-labelledby="demo-controlled-radio-buttons-group"
            name={`question-${props.question?.templateQuestionsId}`}
            value={props.question?.questionValue}
          >
            {props.question?.options?.map(({ value, name }) => (
              <FormControlLabel key={value} value={name} control={<Radio />} label={name} />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    </>
  );
}

export default FeedbackDetailsList;
