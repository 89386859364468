import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Avatar from 'react-avatar';

const SideNav = props => {
  const useStyles = makeStyles(theme =>
    createStyles({
      drawerPaper: {
        width: '20em'
      }
    })
  );
  const classes = useStyles();
  const { currentUser } = props;
  const isAdmin = currentUser?.id === 0;
  return (
    <div id="sideNav" className={props.show ? 'activeSideNav' : 'inactiveSideNav'}>
      <div>
        <Drawer
          classes={{ paper: classes.drawerPaper }}
          anchor="left"
          open={props.show}
          onClose={props.closeSideNav}
        >
          <div
            className="sideNav-user-view"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              alignContent: 'flex-end'
            }}
          >
            {currentUser ? (
              currentUser.photoUrl ? (
                <>
                  <img
                    className="circle"
                    src={currentUser.photoUrl}
                    alt=""
                    style={{ borderRadius: '50%', height: '4em', width: '4em' }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      placeContent: 'center flex-end',
                      alignItems: 'center',
                      flex: '1 1 0%'
                    }}
                  >
                    {currentUser && currentUser.id > 0 ? (
                      <IconButton
                        component={Link}
                        to={`/employees/${currentUser.id}/profile`}
                        size="small"
                        color="inherit"
                      >
                        <AccountCircleIcon />
                      </IconButton>
                    ) : null}
                    <IconButton size="small" color="inherit" onClick={props.logout}>
                      <ExitToAppIcon style={{ color: '#fff' }} />
                    </IconButton>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      minWidth: '100%',
                      width: '100%',
                      flexDirection: 'column',
                      alignItems: 'stretch',
                      placeContent: 'stretch flex-start',
                      maxWidth: '100%'
                    }}
                  >
                    <div className="name white-text">
                      {currentUser.firstName} {currentUser.lastName}
                    </div>
                    <div className="email white-text">{currentUser.jobTitle}</div>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    placeContent: 'center space-between',
                    alignItems: 'center',
                    flex: '1 1 0%'
                  }}
                >
                  {currentUser && currentUser.id > 0 ? (
                    <Button
                      size="small"
                      component={Link}
                      to={`/employees/${currentUser.id}/profile`}
                      style={{
                        padding: '0px',
                        minWidth: '0px',
                        width: '40px',
                        height: '40px',
                        flexShrink: 0,
                        borderRadius: '50%',
                        border: 'none',
                        outline: 'none',
                        textAlign: 'center'
                      }}
                    >
                      <Avatar className="img" name={currentUser?.firstName} size="40" />
                    </Button>
                  ) : null}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'stretch',
                      flexDirection: 'column',
                      placeContent: 'stretch flex-start',
                      maxWidth: '100%'
                    }}
                  >
                    <div
                      className="name"
                      style={{
                        fontSize: '1.25em',
                        lineHeight: '1.5em',
                        fontWeight: 600
                      }}
                    >
                      {currentUser.firstName} {currentUser.lastName}
                    </div>
                    <div
                      className="email white-text"
                      style={{
                        fontSize: '0.9em',
                        lineHeight: '1.5em',
                        fontWeight: 400
                      }}
                    >
                      {currentUser.jobTitle}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      placeContent: 'center flex-end',
                      flex: '1 1 0%'
                    }}
                  >
                    <IconButton size="small" color="inherit" onClick={props.logout}>
                      <ExitToAppIcon style={{ color: '#fff' }} />
                    </IconButton>
                  </div>
                </div>
              )
            ) : null}
          </div>
          <div className="sideNav-listWrapper" style={{ fontSize: '16px' }}>
            <List>
              <div className="sideNav-liWrapper">
                <ListItem button component={Link} to="/">
                  <ListItemText primary="Home" />
                  <HomeIcon style={{ color: '#495057' }} />
                </ListItem>
              </div>
              {!isAdmin && (
                <div className="sideNav-liWrapper">
                  <ListItem button component={Link} to={`/employees/${currentUser?.id}/profile`}>
                    <ListItemText primary="My Profile" />
                    <AccountCircleIcon style={{ color: '#495057' }} />
                  </ListItem>
                </div>
              )}

              {props.showAdministrationLabel() && (
                <ListSubheader disableSticky={true} component="div" className="list-subheader">
                  Administration
                </ListSubheader>
              )}

              {props.hasPermission('departments') && (
                <div className="sideNav-liWrapper">
                  <ListItem button component={Link} to="/admin/departments">
                    <ListItemText primary="Departments" />
                  </ListItem>
                </div>
              )}

              {props.hasPermission('clients') && (
                <div className="sideNav-liWrapper">
                  <ListItem button component={Link} to="/admin/clients">
                    <ListItemText primary="Clients" />
                  </ListItem>
                </div>
              )}
              {props.hasPermission('skills') && (
                <div className="sideNav-liWrapper">
                  <ListItem button component={Link} to="/admin/skills">
                    <ListItemText primary="Skills" />
                  </ListItem>
                </div>
              )}
              {props.hasPermission('tags') && (
                <div className="sideNav-liWrapper">
                  <ListItem button component={Link} to="/admin/tags">
                    <ListItemText primary="Tags" />
                  </ListItem>
                </div>
              )}
              {props.hasPermission('user roles') && (
                <div className="sideNav-liWrapper">
                  <ListItem button component={Link} to="/admin/security">
                    <ListItemText primary="Security" />
                  </ListItem>
                </div>
              )}

              {props.hasPermission('employees') && (
                <>
                  <ListSubheader disableSticky={true} component="div" className="list-subheader">
                    Organization
                  </ListSubheader>
                  <div className="sideNav-liWrapper">
                    <ListItem button component={Link} to="/employees/">
                      <ListItemText primary="Employees" />
                    </ListItem>
                  </div>
                </>
              )}
              {props.showReportLabel() && (
                <ListSubheader disableSticky={true} component="div" className="list-subheader">
                  Reports
                </ListSubheader>
              )}

              {props.hasPermission('reports_system') && (
                <>
                  <div className="sideNav-liWrapper">
                    <ListItem button component={Link} to="/reports/employees/allocation">
                      <ListItemText primary="Allocation" />
                    </ListItem>
                  </div>
                  <div className="sideNav-liWrapper" style={{ display: 'none' }}>
                    <ListItem button component={Link} to="/reports/system">
                      <ListItemText primary="System" />
                    </ListItem>
                  </div>
                </>
              )}
              {props.hasPermission('reports_skills') && (
                <>
                  <div className="sideNav-liWrapper">
                    <ListItem button component={Link} to="/reports/skills/employees">
                      <ListItemText primary="Missing Skills" />
                    </ListItem>
                  </div>
                  <div className="sideNav-liWrapper" style={{ display: 'none' }}>
                    <ListItem button component={Link} to="/reports/skills/">
                      <ListItemText primary="Inventory" />
                    </ListItem>
                  </div>
                  <div style={{ display: 'none', paddingLeft: '2em' }}>
                    <ListItem button component={Link} to="/reports/skills/trends">
                      <ListItemText primary="Trends" />
                    </ListItem>
                  </div>
                  <div className="sideNav-liWrapper">
                    <ListItem button component={Link} to="/reports/skills/statistics">
                      <ListItemText primary="Skills Statistics" />
                    </ListItem>
                  </div>
                  <div className="sideNav-liWrapper">
                    <ListItem button component={Link} to="/reports/skills/employee-skills">
                      <ListItemText primary="Employee Skills" />
                    </ListItem>
                  </div>
                  <div className="sideNav-liWrapper">
                    <ListItem button component={Link} to="/reports/skills/employees-all-skills">
                      <ListItemText primary="Employees All Skills" />
                    </ListItem>
                  </div>
                </>
              )}
              {(props.hasPermission('feedback_request') ||
                props.hasPermission('feedback_management')) && (
                <ListSubheader disableSticky={true} component="div" className="list-subheader">
                  Employee Feedback
                </ListSubheader>
              )}
              {props.hasPermission('feedback_request') && (
                <div className="sideNav-liWrapper">
                  <ListItem button component={Link} to="/feedback">
                    <ListItemText primary="Requests" />
                  </ListItem>
                </div>
              )}
              {props.hasPermission('feedback_management') && (
                <>
                  <div className="sideNav-liWrapper">
                    <ListItem button component={Link} to="/template">
                      <ListItemText primary="Templates" />
                    </ListItem>
                  </div>
                  <div className="sideNav-liWrapper">
                    <ListItem button component={Link} to="/question">
                      <ListItemText primary="Questions" />
                    </ListItem>
                  </div>
                </>
              )}
            </List>
          </div>
        </Drawer>
      </div>
    </div>
  );
};
export default SideNav;
