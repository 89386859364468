import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import LayoutContainer from '../../../shared/components/_layout/layout-container/LayoutContainer';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton } from '@material-ui/core';
import GridViewIcon from '@material-ui/icons/GridOn';
import ViewListIcon from '@material-ui/icons/ViewList';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import SearchBarCotainer from 'modules/shared/components/top-bars/search-bar/SearchBarContainer';
import { feedbackService } from 'modules/Feedback/services/feedback';
import Feedbacks from './Feedbacks';
import './FeedbackList.scss';

const FeedbackListContainer = props => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);
  const itemsPerPage = 12;
  const savedPage = sessionStorage.getItem('feedbacks.currentPage');
  const savedView = localStorage.getItem('view');
  const [page, setPage] = useState(savedPage ? parseInt(savedPage) : 1);
  const [view, setView] = useState(savedView);

  const filterFeedback = (name = '') => {
    if (name === '') {
      setFilteredFeedbacks(feedbacks);
    } else {
      const filteredFeedbacks = feedbacks.filter(
        employee =>
          `${employee.firstName} ${employee.lastName}`.toLowerCase().indexOf(name.toLowerCase()) >
          -1
      );
      setFilteredFeedbacks(filteredFeedbacks);
    }
  };

  const navigate = route => {
    props.history.push(route, { prevPathname: props?.location?.pathname });
  };
  const fetchData = async () => {
    setIsLoading(true);
    const feedbackData = await feedbackService.getAllFeedbacks();
    setFeedbacks(feedbackData?.data);
    setFilteredFeedbacks(feedbackData?.data);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
    localStorage.setItem('pathname', props?.location?.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangeView = viewType => {
    localStorage.setItem('view', viewType);
    setView(viewType);
    sessionStorage.setItem('feedbacks.currentPage', '1');
    setPage(1);
  };
  const openClientDialog = client => {
    setIsDialogOpen(true);
  };
  const closeClientDialog = () => {
    setIsDialogOpen(false);
  };
  const titleBarPrimary = toggleMode => (
    <>
      <IconButton
        style={{ color: 'inherit' }}
        onClick={() => {
          toggleMode(true);
        }}
      >
        <SearchIcon />
      </IconButton>
    </>
  );
  const searchBarCommandPlaceholder = (
    <Fab
      style={{ position: 'relative', top: '2em' }}
      onClick={() => openClientDialog()}
      color="secondary"
      aria-label="Add feedback"
    >
      <AddIcon />
    </Fab>
  );
  const titleBar = (
    <SearchBarCotainer
      title="Employee Feedback Requests"
      backRoute="/"
      titleBarPrimary={titleBarPrimary}
      filter={filterFeedback}
      searchBarCommandPlaceholder={searchBarCommandPlaceholder}
    ></SearchBarCotainer>
  );
  const drawerWidth = 350;
  return (
    <LayoutContainer titleBar={titleBar} drawerWidth={drawerWidth}>
      <div>
        <div className="viewControlWrapper">
          <div></div>
          {feedbacks?.length > 0 && (
            <div className="viewOptions">
              <ViewListIcon
                className={`option ${view === 'list' ? 'active' : ''}`}
                onClick={() => {
                  handleChangeView('list');
                }}
              />
              <GridViewIcon
                className={`option ${view === 'grid' ? 'active' : ''}`}
                onClick={() => {
                  handleChangeView('grid');
                }}
              />
            </div>
          )}
        </div>
        <Feedbacks
          feedbacks={filteredFeedbacks}
          navigate={navigate}
          isLoading={isLoading}
          view={view}
          itemsPerPage={itemsPerPage}
          page={page}
          setPage={setPage}
          isDialogOpen={isDialogOpen}
          closeClientDialog={closeClientDialog}
          fetchFeedbackData={fetchData}
        />
      </div>
    </LayoutContainer>
  );
};
const mapStateToProps = store => ({
  currentUser: store.auth.currentUser,
  userPermissions: store.auth.userPermissions
});
export default connect(mapStateToProps, null)(FeedbackListContainer);
