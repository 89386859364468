import { useState, useEffect } from 'react';
import Questions from './Questions/Questions';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { feedbackService } from 'modules/Feedback/services/feedback';
import './Template.scss';
const Template = ({ template, feedbackId }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [answers, setAnswers] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const reshapeAnswers = answers => {
    return Object.entries(answers).map(([questionId, value]) => ({
      questionId: parseInt(questionId),
      value: value
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const questionsAnswers = reshapeAnswers(answers);
    try {
      setLoading(true);
      const response = await feedbackService.createFeedbackResponse(feedbackId, questionsAnswers);
      history.push(`/response-confirmation`);
      localStorage.removeItem('idToken');
      return response;
    } catch (error) {
      console.error('Error submitting feedback:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAnswerChange = (questionId, value) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: value
    }));
  };

  useEffect(() => {
    const allQuestionsAnswered =
      template?.questions?.every(question => answers[question.questionId]) || false;
    setIsFormValid(allQuestionsAnswered);
  }, [answers, template?.questions]);

  return (
    <div className="templateContainer">
      <h2 className="templateName">
        {feedbackId ? `Feedback For: ${template?.employeeName}` : template?.name}
      </h2>
      <div className="questionsContainer">
        <form className="formQuestions" onSubmit={handleSubmit}>
          {template?.questions?.map((question, index) => (
            <Questions
              key={index}
              index={index + 1}
              question={question}
              feedbackId={feedbackId}
              handleAnswerChange={handleAnswerChange}
              answers={answers}
            />
          ))}
          {feedbackId && (
            <Button
              className={loading || !isFormValid ? 'loadingBtn showBtn' : 'showBtn'}
              color="primary"
              type="submit"
              disabled={loading || !isFormValid}
            >
              Submit
            </Button>
          )}
        </form>
      </div>
    </div>
  );
};

export default Template;
