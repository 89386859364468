import { useEffect, useState } from 'react';
import LayoutContainer from 'modules/shared/components/_layout/layout-container/LayoutContainer';
import SearchBarCotainer from 'modules/shared/components/top-bars/search-bar/SearchBarContainer';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton } from '@material-ui/core';
import QuestionList from './QuestionList';
import DetailsAuditSideContainer from 'modules/shared/components/_layout/detail-audit-side-container/DetailsAuditSideContainer';
import { questionService } from '../../services/question';

function QuestionListContainer(props) {
  const [questions, setQuestions] = useState([]);
  const [questionsFilter, setQuestionsFilter] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [audits, setAudits] = useState([]);
  const [sideData, setSideData] = useState(undefined);
  const [dialogData, setDialogData] = useState(undefined);

  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successToastText, setSuccessToastText] = useState('');
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorToastText, setErrorToastText] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [toDelete, setToDelete] = useState(undefined);
  const [isQuestionInfoDialogOpen, setIsQuestionInfoDialogOpen] = useState(false);
  const [questionInformation, setQuestionInformation] = useState(undefined);
  const [isOptionsDialogOpen, setIsOptionsDialogOpen] = useState(false);
  const [dialogOptionsData, setDialogOptionsData] = useState(undefined);

  const onFilter = async (name = '') => {
    if (name === '' && typeof name !== 'string') {
      setQuestionsFilter(questions);
    } else {
      const filteredQuestions = questions.filter(
        question => `${question?.description}`.toLowerCase().indexOf(name.toLowerCase()) > -1
      );
      setQuestionsFilter(filteredQuestions);
    }
  };
  const openQuestionDialog = question => {
    setIsDialogOpen(true);
    setDialogData(question);
  };

  const openOptionsDialog = data => {
    setIsOptionsDialogOpen(true);
    setDialogOptionsData({ questionId: data?.id, options: data?.QuestionOptions });
  };

  const fetchData = async () => {
    setIsLoading(true);
    const questionData = await questionService.getAllQuestions();
    setQuestions(questionData?.data);
    setQuestionsFilter(questionData?.data);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
    localStorage.setItem('pathname', props?.location?.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeQuestionDialog = () => {
    setIsDialogOpen(false);
    setDialogData(undefined);
  };

  const closeOptionsDialog = () => {
    setIsOptionsDialogOpen(false);
    setDialogOptionsData(undefined);
  };

  const onOptionsCreate = (state, errorMessage) => {
    if (state === 'success') {
      setShowSuccessToast(true);
      setSuccessToastText('Options saved successfully');
      setIsOptionsDialogOpen(false);
      setDialogOptionsData(undefined);
    } else {
      setShowErrorToast(true);
      setErrorToastText(errorMessage);
    }
  };

  const onQuestionCreate = (state, errorMessage) => {
    if (state === 'success') {
      setShowSuccessToast(true);
      setSuccessToastText('Question saved successfully');
      setIsDialogOpen(false);
      setDialogData(undefined);
    } else {
      setShowErrorToast(true);
      setErrorToastText(errorMessage);
    }
  };

  const closeSuccessToast = () => {
    setShowSuccessToast(false);
    setSuccessToastText('');
  };

  const closeErrorToast = () => {
    setShowErrorToast(false);
    setErrorToastText('');
  };

  const headerContent = (
    <div
      className="DetailsAuditSideHeader"
      style={{
        display: 'flex',
        alignItems: 'center',
        placeContent: 'center flex-start',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }}
    >
      <div
        className="sideRoleName"
        style={{
          font: '500 20px/32px',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
      >
        {sideData?.name}
      </div>
    </div>
  );
  const titleBarPrimary = toggleMode => (
    <>
      <IconButton
        style={{ color: 'inherit' }}
        onClick={() => {
          toggleMode(true);
        }}
      >
        <SearchIcon />
      </IconButton>
    </>
  );
  const searchBarCommandPlaceholder = (
    <Fab
      style={{ position: 'relative', top: '2em' }}
      onClick={() => openQuestionDialog()}
      color="secondary"
      aria-label="Add question"
    >
      <AddIcon />
    </Fab>
  );
  const titleBar = (
    <SearchBarCotainer
      title="Question"
      backRoute="/"
      titleBarPrimary={titleBarPrimary}
      filter={onFilter}
      searchBarCommandPlaceholder={searchBarCommandPlaceholder}
    ></SearchBarCotainer>
  );

  const closeDeleteDialog = async data => {
    if (data && data.id) {
      try {
        await questionService.updateQuestion(data.id, { active: data?.active });
        fetchData();
        setShowSuccessToast(true);
        setSuccessToastText('Change status of the question successfully!');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);
      } catch {
        setShowErrorToast(true);
        setErrorToastText('Cannot change status of the question');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);
      }
    }
    setIsDeleteDialogOpen(false);
  };
  const confirmInactiveQuestion = question => {
    const toDelete = {
      id: question.id,
      description: question.description,
      active: question.active
    };
    setToDelete(toDelete);
    setIsDeleteDialogOpen(true);
  };

  const questionInfoFunction = question => {
    const questionInfo = {
      id: question?.id,
      type: question?.type,
      description: question?.description,
      active: question?.active,
      options: question?.QuestionOptions.map(op => ({
        optionId: op.id,
        name: op.name,
        value: op.value,
        active: op.active
      }))
    };
    setQuestionInformation(questionInfo);
    setIsQuestionInfoDialogOpen(true);
  };

  const closeQuestionInfoDialog = async () => {
    setIsQuestionInfoDialogOpen(false);
  };

  const sideContent = closeDrawer => (
    <DetailsAuditSideContainer
      headerContent={headerContent}
      audit={audits}
      closeDrawer={closeDrawer}
    />
  );
  const drawerWidth = 350;
  return (
    <LayoutContainer titleBar={titleBar} sideContent={sideContent} drawerWidth={drawerWidth}>
      <QuestionList
        questions={questionsFilter}
        isDialogOpen={isDialogOpen}
        closeQuestionDialog={closeQuestionDialog}
        openQuestionDialog={openQuestionDialog}
        onQuestionCreate={onQuestionCreate}
        dialogData={dialogData}
        isLoading={isLoading}
        errorToastText={errorToastText}
        closeErrorToast={closeErrorToast}
        showErrorToast={showErrorToast}
        successToastText={successToastText}
        closeSuccessToast={closeSuccessToast}
        showSuccessToast={showSuccessToast}
        toDelete={toDelete}
        confirmInactiveQuestion={confirmInactiveQuestion}
        closeDeleteDialog={closeDeleteDialog}
        isDeleteDialogOpen={isDeleteDialogOpen}
        questionInfoFunction={questionInfoFunction}
        closeQuestionInfoDialog={closeQuestionInfoDialog}
        isQuestionInfoDialogOpen={isQuestionInfoDialogOpen}
        questionInformation={questionInformation}
        fetchData={fetchData}
        onOptionsCreate={onOptionsCreate}
        isOptionsDialogOpen={isOptionsDialogOpen}
        closeOptionsDialog={closeOptionsDialog}
        dialogOptionsData={dialogOptionsData}
        openOptionsDialog={openOptionsDialog}
      />
    </LayoutContainer>
  );
}

export default QuestionListContainer;
