import FeedbackDetailsList from '../feedbackDetailsList/feedbackDetailsList';
import './FeedbackDetails.scss';

const FeedbackDetails = props => {
  return (
    <div id="FeedbackDetails">
      <div className="employeeInfo">Feedback For: {props?.feedbackResponse?.employeeName}</div>

      <div className="feedbackResponseDetails">
        {props?.feedbackResponse?.questionsFeedbackResponse?.map((question, index) => (
          <FeedbackDetailsList question={question} key={question.id} index={index + 1} />
        ))}
      </div>
    </div>
  );
};

export default FeedbackDetails;
