import ShowMessage from 'components/ShowMessage/ShowMessage';

const ResponseConfirmation = () => {
  return (
    <ShowMessage>
      <h1>Thank You!</h1>
      <p>
        Your feedback has been successfully submitted. We appreciate your time and effort in helping
        us improve our services.
      </p>
    </ShowMessage>
  );
};

export default ResponseConfirmation;
