import { TextareaAutosize, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import './Questions.scss';
const Questions = ({ question, feedbackId, handleAnswerChange, answers, index }) => {
  const handleChange = (e, question) => {
    handleAnswerChange(question?.questionId, e.target.value);
  };
  return (
    <div className="questionsOptionsContainer">
      <>
        <label className="questionLabel">
          {index}) {question.questionDescription}
        </label>
        {question.questionType === 'text' ? (
          <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            className="noResize"
            disabled={feedbackId === null}
            value={answers[question?.questionId] || ''}
            onChange={e => handleChange(e, question)}
          />
        ) : (
          <FormControl disabled={feedbackId === null}>
            <RadioGroup
              className="radioGroup"
              aria-labelledby="demo-controlled-radio-buttons-group"
              name={`question-${question?.templateQuestionsId}`}
              value={answers[question?.questionId] || ''}
            >
              {question?.questionOptions?.map(({ value, name }) => (
                <FormControlLabel
                  className="optionLabel"
                  key={value}
                  value={name}
                  control={<Radio />}
                  label={name}
                  onChange={e => handleChange(e, question)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </>
    </div>
  );
};

export default Questions;
