import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import './QuestionTemplate.scss';
function QuestionTemplate(props) {
  return (
    <div className="questionTemplateContainer">
      <div className="containerHead">
        <h3 className="questionDescription">{props?.question?.description}</h3>
        <p className="questionType">{props?.question?.type}</p>
      </div>
      <div className="containerBody">
        {props?.question.options && props?.question.options.length > 0 && (
          <FormControl disabled className="radioGroupContainer">
            <RadioGroup
              className="radioGroup"
              aria-labelledby="demo-controlled-radio-buttons-group"
            >
              {props?.question.options?.map(({ value, name }) => (
                <FormControlLabel key={value} value={name} control={<Radio />} label={name} />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </div>
    </div>
  );
}

export default QuestionTemplate;
