import { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { feedbackService } from 'modules/Feedback/services/feedback';
import Template from '../Template/Template';
import './FeedbackRequest.scss';
const FeedbackRequest = props => {
  const [templates, setTemplates] = useState([]);
  const [clients, setClients] = useState([]);
  const [template, setTemplate] = useState({});
  const [directReportsEmployee, setDirectReportsEmployee] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [feedbackData, setFeedbackData] = useState({
    employeeId: null,
    requestedName: '',
    requestedEmail: '',
    clientId: null,
    requesterId: props?.currentUser?.id,
    templateId: null
  });

  const checkValidity = value => {
    if (!(Object.values(value).includes('') || Object.values(value).includes(null))) return true;
    return false;
  };

  const reset = () => {
    setFeedbackData({
      employeeId: null,
      requestedName: '',
      requestedEmail: '',
      clientId: null,
      requesterId: props?.currentUser?.id,
      templateId: null
    });
    setIsValid(false);
  };
  const cancel = () => {
    reset();
    props.closeDialog();
  };

  const templateOnChange = async templateId => {
    const template = await feedbackService.getTemplateById(templateId);
    setTemplate(template?.data);
    const updatedFeedback = { ...feedbackData, templateId: template?.data?.id };
    setIsValid(false);
    if (checkValidity(updatedFeedback)) {
      setIsValid(true);
    }
    setFeedbackData(updatedFeedback);
  };

  const handleNameChange = e => {
    const updatedFeedback = { ...feedbackData, [e.target.name]: e.target.value };
    setIsValid(false);
    if (checkValidity(updatedFeedback)) {
      setIsValid(true);
    }
    setFeedbackData(updatedFeedback);
  };
  const handleEmailChange = e => {
    const updatedFeedback = { ...feedbackData, [e.target.name]: e.target.value };
    setIsValid(false);
    if (checkValidity(updatedFeedback)) {
      setIsValid(true);
    }
    setFeedbackData(updatedFeedback);
  };

  const onSubmit = async () => {
    await feedbackService.createFeedback(feedbackData);
    props.closeDialog();
    props.fetchFeedbackData();
  };

  useEffect(() => {
    async function getData() {
      const templates = await feedbackService.getTemplates();
      setTemplates(templates.data);
      const clients = await feedbackService.getClients();
      setClients(clients.data);
      const employees = await feedbackService.getEmployeeById(props?.currentUser?.id);
      setDirectReportsEmployee(employees?.data?.direct_reports);
    }
    getData();
  }, [props?.currentUser?.id]);
  return (
    <div id="FeedbackRequest">
      <div className="FeedbackRequestWrapper">
        <div
          id="feedbackRequestForm"
          style={{ display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column' }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center'
            }}
          >
            <FormControl style={{ flex: '1 0', marginRight: '0.25em' }}>
              <TextField
                onChange={e => {
                  handleNameChange(e);
                }}
                name="requestedName"
                required
                margin="dense"
                id="RequestedName"
                label="Requested From Name"
                type="text"
                fullWidth
              />
            </FormControl>
            <FormControl style={{ flex: '1 0', marginRight: '0.25em' }}>
              <TextField
                onChange={e => {
                  handleEmailChange(e);
                }}
                name="requestedEmail"
                required
                margin="dense"
                id="RequestedEmail"
                label="Requested From Email"
                type="text"
                fullWidth
              />
            </FormControl>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              margin: '1.5rem 0'
            }}
          >
            <FormControl style={{ flex: '1 0', marginRight: '0.25em' }}>
              <Autocomplete
                id="client-select"
                name="client"
                ref={props?.groupRef}
                options={clients}
                onChange={(event, client) => {
                  if (client?.id) {
                    const updatedFeedback = { ...feedbackData, clientId: client.id };
                    setIsValid(false);
                    if (checkValidity(updatedFeedback)) {
                      setIsValid(true);
                    }
                    setFeedbackData(updatedFeedback);
                  } else {
                    setIsValid(false);
                    setFeedbackData({ ...feedbackData, clientId: null });
                  }
                }}
                getOptionLabel={option => option.name}
                getOptionDisabled={option => option.disabled}
                renderInput={params => <TextField {...params} label="Client" />}
              />
            </FormControl>
            <FormControl style={{ flex: '1 0', marginRight: '0.25em' }}>
              <Autocomplete
                id="employee-select"
                name="employee"
                ref={props?.groupRef}
                options={directReportsEmployee}
                getOptionLabel={option => `${option.first_name} ${option.last_name}`}
                onChange={(event, employee) => {
                  if (employee?.id) {
                    const updatedFeedback = { ...feedbackData, employeeId: employee?.id };
                    setIsValid(false);
                    if (checkValidity(updatedFeedback)) {
                      setIsValid(true);
                    }
                    setFeedbackData(updatedFeedback);
                  } else {
                    setIsValid(false);
                    setFeedbackData({ ...feedbackData, employeeId: null });
                  }
                }}
                getOptionDisabled={option => option.disabled}
                renderInput={params => <TextField {...params} label="Employee" />}
              />
            </FormControl>
            <FormControl style={{ flex: '1 0', marginRight: '0.25em' }}>
              <Autocomplete
                id="template-select"
                name="template"
                ref={props?.groupRef}
                options={templates}
                onChange={(event, template) => {
                  if (template?.id) {
                    templateOnChange(template?.id);
                  } else {
                    setTemplate({});
                    setIsValid(false);
                  }
                }}
                getOptionLabel={option => option.name}
                getOptionDisabled={option => option.disabled}
                renderInput={params => <TextField {...params} label="Template" />}
              />
            </FormControl>
          </div>
        </div>
        <div className="showTemplate">
          {template?.name && <Template template={template} feedbackId={null} />}
        </div>
        <div className="feedbackRequestActions">
          <Button onClick={cancel}>Cancel</Button>
          <Button
            onClick={() => {
              onSubmit();
            }}
            className={!isValid ? 'disabledBtn' : 'enabledBtn'}
            color="primary"
          >
            {'Create'}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default FeedbackRequest;
