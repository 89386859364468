import { useState, useEffect } from 'react';
import { Select, InputLabel, FormControl, TextField, Button, MenuItem } from '@material-ui/core';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { questionService } from '../../services/question';
import './QuestionDialog.scss';

function QuestionDialogContainer(props) {
  const [question, setQuestion] = useState({
    id: props?.data?.id || null,
    description: props?.data?.description || '',
    type: props?.data?.type || ''
  });
  const [isValid, setIsValid] = useState(false);

  const [options, setOptions] = useState(props?.data?.QuestionOptions || []);

  const [selectedOptions, setSelectedOptions] = useState({});

  const [optionValue, setOptionValue] = useState('');

  const result = Object?.entries(selectedOptions)
    ?.filter(([key, value]) => value !== false)
    ?.map(([key, value]) => ({
      value: key,
      name: value
    }));
  const reset = () => {
    setQuestion({
      id: null,
      description: '',
      type: ''
    });
    setIsValid(false);
  };

  const handleChange = e => {
    const updatedQuestion = { ...question, [e.target.name]: e.target.value, options: result };
    let isValid = false;
    if (checkValidity(updatedQuestion)) {
      isValid = true;
    }
    setQuestion(updatedQuestion);
    setIsValid(isValid);
  };

  const checkValidity = question => {
    const hasDescription = question.description?.trim() !== '';
    const hasType = question.type !== '';
    const hasOptionsForRating =
      (question.type === 'rating' && question.options?.length > 0) || question.type === 'text';

    return hasDescription && hasType && hasOptionsForRating;
  };

  const cancel = () => {
    reset();
    props.closeDialog();
  };

  const saveQuestion = async () => {
    const data = {
      ...question,
      description: question.description?.trim(),
      options: result?.length > 0 ? result : null
    };
    if (data.id && data.id !== null && data.id !== undefined) {
      try {
        await questionService.updateQuestion(data?.id, data);
        props?.fetchData();
        reset();
        props.onQuestionCreate('success');
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        props.onQuestionCreate('fail', errorMessage);
      }
    } else {
      try {
        await questionService.addQuestion({
          type: data.type,
          description: data.description,
          options: result?.length > 0 ? result : null
        });
        props?.fetchData();
        reset();
        props.onQuestionCreate('success');
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        props.onQuestionCreate('fail', errorMessage);
      }
    }
  };

  const handleOptionChange = e => {
    setOptionValue(e.target.value);
  };

  const handelCreateOption = () => {
    setOptions(prev => {
      const maxValue = Math.max(0, ...prev.map(option => Number(option.value)));
      return [
        ...prev,
        {
          name: optionValue,
          value: maxValue + 1
        }
      ];
    });
    setOptionValue('');
  };

  useEffect(() => {
    const initialOptions = options?.reduce((acc, option) => {
      acc[option.value] = option.name;
      return acc;
    }, {});

    setSelectedOptions(initialOptions);
  }, []);

  useEffect(() => {
    const updatedQuestion = { ...question, options: result };
    let isValid = false;
    if (checkValidity(updatedQuestion)) {
      isValid = true;
    }
    setIsValid(isValid);
  }, [result]);

  const handleOptionsChange = (event, option) => {
    setSelectedOptions(prevState => ({
      ...prevState,
      [option.value]: !event.target.checked ? false : option.name
    }));
  };

  return (
    <div id="QuestionDialog">
      <div className="QuestionDialogTitle">{question.id ? 'Edit Question' : 'New Question'}</div>

      <div className="QuestionDialogContent">
        <TextField
          onChange={e => {
            handleChange(e);
          }}
          defaultValue={question.description}
          description="description"
          name="description"
          required
          autoFocus
          margin="dense"
          id="Description"
          label="Description"
          type="text"
          fullWidth
        />
        <FormControl required className="questionTypeSelect">
          <InputLabel id="questionType">Question Type</InputLabel>
          <Select
            value={question?.type}
            name="type"
            labelId="questionType"
            id="availabilityJordan"
            onChange={handleChange}
          >
            <MenuItem value={'text'}>Text</MenuItem>
            <MenuItem value={'rating'}>Rating</MenuItem>
          </Select>
        </FormControl>
      </div>

      {question?.type === 'rating' && (
        <div className="createOptionContainer">
          <FormGroup className="optionsPox">
            {options?.map(option => (
              <FormControlLabel
                key={option.name}
                control={
                  <Checkbox
                    checked={selectedOptions[option.value] ? true : false}
                    onChange={event => handleOptionsChange(event, option)}
                  />
                }
                label={option?.name}
              />
            ))}
          </FormGroup>
          <div className="addOption">
            <TextField
              onChange={e => {
                handleOptionChange(e);
              }}
              value={optionValue}
              description="option"
              name="option"
              required
              autoFocus
              margin="dense"
              id="Option"
              label="Option"
              type="text"
              className="addOptionInput"
            />
            <Button
              onClick={handelCreateOption}
              disabled={!optionValue.length}
              className="btnAddOption"
            >
              add
            </Button>
          </div>
        </div>
      )}

      <div className="QuestionDialogActions">
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={saveQuestion} disabled={!isValid} color="primary">
          {question.id ? 'Save' : 'Create'}
        </Button>
      </div>
    </div>
  );
}

export default QuestionDialogContainer;
