import { axiosInstances } from 'utilities/axios/Instances/axios.instances';
const add = data => {
  return axiosInstances.withAuthToken().post('/templates', data);
};
const update = (id, data) => {
  return axiosInstances.withAuthToken().patch(`/templates/${id}`, data);
};
const deleteTemplate = id => {
  return axiosInstances.withAuthToken().delete(`/templates/${id}`);
};
export const templateService = {
  add,
  update,
  deleteTemplate
};
