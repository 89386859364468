import { useEffect, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import './EmployeeChipList.scss';
const EmployeeChipList = props => {
  const [values, setValues] = useState([]);
  useEffect(() => setValues([...props.employees]), [props.employees]);
  const handleChange = value => {
    if (props.multiple) {
      setValues(value);
      props.handleEmployeesChange(value);
    } else {
      if (value.length > 0) {
        setValues([value[value.length - 1]]);
        props.handleEmployeesChange([value[value.length - 1]]);
      }
    }
    //check if reached max number of employees before adding a new one
  };
  return (
    <>
      <div id="EmployeeChipList">
        <div className="form-group">
          {/* {props.showPrefix && <PermIdentityIcon />} */}
          <Autocomplete
            fullWidth={props.fullWidth}
            loading={props.isLoading}
            value={values}
            multiple
            id="tags-standard"
            options={props.allEmployees}
            onChange={(e, newValue) => {
              handleChange(newValue);
            }}
            filterSelectedOptions
            getOptionLabel={option => option.firstName + ' ' + option.lastName}
            // defaultValue={props.filteredEmployees}
            getOptionSelected={(option, value) => option.id === value.id}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option.firstName + ' ' + option.lastName}
                  avatar={<Avatar alt={option.firstName} src={option?.photoUrl} />}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderOption={option => (
              <>
                <Avatar alt={option.firstName} src={option.photoUrl} />
                <span style={{ marginLeft: '0.5em' }}>
                  {option.firstName} {option.lastName} | <small>{option.jobTitle}</small>
                </span>
              </>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                name={props?.name}
                label={props.placeholder || 'Employees'}
                placeholder={props.placeholder || 'add'}
                required={props?.required}
                error={props?.error}
                onBlur={props?.onBlur}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};
export default EmployeeChipList;
