import Button from '@material-ui/core/Button';
import './ConfirmDialogComponent.scss';
const ConfirmDialogComponent = props => {
  return (
    <div className="confirmDeleteDialog">
      <div className="confirmDialogTitle">Delete {props.data?.type}</div>
      <div className="confirmDialogContent">
        <div>
          <b className="truncate">{props.data?.name || 'this item'}</b>
        </div>
        <div>Will be permenantly removed. Are you sure?</div>
      </div>
      <div className="confermDialogActions">
        <Button onClick={props.close} focusRipple={true}>
          No
        </Button>
        <Button onClick={() => props.close(props.data)} color="primary">
          Yes
        </Button>
      </div>
    </div>
  );
};
export default ConfirmDialogComponent;
