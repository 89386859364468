import { Button } from '@material-ui/core';
import QuestionTemplate from '../question-template/QuestionTemplate';
import './QuestionInfoDialog.scss';
function QuestionInfoDialog(props) {
  return (
    <div id="QuestionInfoContainer">
      <QuestionTemplate question={props?.question} />
      <div className="btnContainer">
        <Button className="closeBtn" onClick={props.close}>
          Close
        </Button>
      </div>
    </div>
  );
}

export default QuestionInfoDialog;
