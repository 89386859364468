import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import QuestionChipListContainer from '../questionChipList/QuestionChipListContainer';
const TemplateDialog = props => {
  return (
    <div id="RoleDialog">
      <div className="RoleDialogTitle">
        {props.template.id ? props.template.name : 'New Template'}
      </div>

      <div className="RoleDialogContent">
        <TextField
          onChange={e => {
            props.handleNameChange(e);
          }}
          defaultValue={props.template.name}
          name="name"
          required
          autoFocus
          margin="dense"
          id="Name"
          label="Name"
          type="text"
          fullWidth
        />
        <QuestionChipListContainer
          handleQuestionsChange={props.handleQuestionsChange}
          questions={props.template.questions}
        />
      </div>

      <div className="RoleDialogActions">
        <Button onClick={props.cancel}>Cancel</Button>
        <Button onClick={props.saveTemplate} disabled={!props.isValid} color="primary">
          {props.template.id ? 'Save' : 'Create'}
        </Button>
      </div>
    </div>
  );
};
export default TemplateDialog;
