import { useState } from 'react';
import { templateService } from '../../services/template';
import './TemplateDialog.scss';
import TemplateDialog from './TemplateDialog';

const TemplateDialogContainer = props => {
  const [template, setTemplate] = useState({
    id: props?.data?.id || undefined,
    name: props?.data?.name || '',
    questions: props?.data?.TemplateQuestions?.map(e => e?.Question?.id) || []
  });
  const [isValid, setIsValid] = useState(false);

  const reset = () => {
    setTemplate({
      id: undefined,
      name: '',
      questions: []
    });
    setIsValid(false);
  };

  const handleNameChange = e => {
    const updatedTemplate = { ...template, [e.target.name]: e.target.value };
    let isValid = false;
    if (checkValidity(updatedTemplate)) {
      isValid = true;
    }
    setTemplate(updatedTemplate);
    setIsValid(isValid);
  };
  const handleQuestionsChange = value => {
    const queIds = value.map(que => +que.id);
    const updatedTemplate = { ...template, questions: queIds };
    let isValid = false;
    if (checkValidity(updatedTemplate)) {
      isValid = true;
    }
    setTemplate(updatedTemplate);
    setIsValid(isValid);
  };

  const checkValidity = template => {
    if (template.name?.trim() !== '' && template.questions?.length !== 0) {
      return true;
    }
    return false;
  };

  const cancel = () => {
    reset();
    props.closeDialog();
  };

  const saveTemplate = async () => {
    const data = { ...template, name: template.name?.trim() };
    if (data.id && data.id !== null && data.id !== undefined) {
      try {
        await templateService.update(data?.id, data);
        props?.fetchData();
        reset();
        props.onTemplateCreate('success');
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        props.onTemplateCreate('fail', errorMessage);
      }
    } else {
      try {
        await templateService.add(data);
        props?.fetchData();
        reset();
        props.onTemplateCreate('success');
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        props.onTemplateCreate('fail', errorMessage);
      }
    }
  };

  return (
    <TemplateDialog
      template={template}
      isValid={isValid}
      cancel={cancel}
      saveTemplate={saveTemplate}
      handleNameChange={handleNameChange}
      handleQuestionsChange={handleQuestionsChange}
    />
  );
};

export default TemplateDialogContainer;
