import { useEffect, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';

function QuestionChipList(props) {
  const [values, setValues] = useState([]);
  useEffect(() => setValues([...props.questions]), [props.questions]);
  const handleChange = value => {
    if (props.multiple) {
      setValues(value);
      props.handleQuestionsChange(value);
    } else {
      if (value.length > 0) {
        setValues([value[value.length - 1]]);
        props.handleQuestionsChange([value[value.length - 1]]);
      }
    }
  };
  return (
    <>
      <div id="EmployeeChipList">
        <div className="form-group">
          <Autocomplete
            fullWidth={props.fullWidth}
            loading={props.isLoading}
            value={values}
            multiple
            id="tags-standard"
            options={props.allQuestions}
            onChange={(e, newValue) => {
              handleChange(newValue);
            }}
            filterSelectedOptions
            getOptionLabel={option => option.description}
            getOptionSelected={(option, value) => option.id === value.id}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option.description}
                  avatar={<Avatar alt={option.description} src={option?.photoUrl} />}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderOption={option => (
              <>
                <span style={{ marginLeft: '0.5em', borderBottom: '0.5px solid #000' }}>
                  {option.description} | <small>{option.type}</small>
                </span>
              </>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                name={props?.name}
                label={props.placeholder || 'Question'}
                placeholder={props.placeholder || 'add'}
                required={props?.required}
                error={props?.error}
                onBlur={props?.onBlur}
              />
            )}
          />
        </div>
      </div>
    </>
  );
}

export default QuestionChipList;
