import Template from 'modules/Feedback/components/Template/Template';
import { Button } from '@material-ui/core';
import './TemplateInfoDialog.scss';
function TemplateInfoDialog(props) {
  return (
    <div id="TemplateInfoContainer">
      <Template template={props.template} feedbackId={null} />

      <div className="btnContainer">
        <Button className="closeBtn" onClick={props.close}>
          Close
        </Button>
      </div>
    </div>
  );
}

export default TemplateInfoDialog;
