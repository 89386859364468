import { useEffect, useState } from 'react';
import LayoutContainer from 'modules/shared/components/_layout/layout-container/LayoutContainer';
import SearchBarCotainer from 'modules/shared/components/top-bars/search-bar/SearchBarContainer';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton } from '@material-ui/core';
import TemplateList from './TemplateList';
import DetailsAuditSideContainer from 'modules/shared/components/_layout/detail-audit-side-container/DetailsAuditSideContainer';
import { feedbackService } from 'modules/Feedback/services/feedback';
import { templateService } from 'modules/Template/services/template';
const TemplateListContainer = props => {
  const [templates, setTemplates] = useState([]);
  const [templateFilter, setTemplateFilter] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [audits, setAudits] = useState([]);
  const [sideData, setSideData] = useState(undefined);
  const [dialogData, setDialogData] = useState(undefined);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successToastText, setSuccessToastText] = useState('');
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorToastText, setErrorToastText] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [toDelete, setToDelete] = useState(undefined);
  const [isTemplateInfoDialogOpen, setIsTemplateInfoDialogOpen] = useState(false);
  const [templateInformation, setTemplateInformation] = useState(undefined);

  const onFilter = async (name = '') => {
    if (name === '') {
      setTemplateFilter(templates);
    } else {
      const filteredTemplates = templates.filter(
        template => `${template?.name}`?.toLowerCase()?.indexOf(name?.toLowerCase()) > -1
      );
      setTemplateFilter(filteredTemplates);
    }
  };
  const openTemplateDialog = template => {
    setIsDialogOpen(true);
    setDialogData(template);
  };

  const fetchData = async () => {
    setIsLoading(true);
    const templatesData = await feedbackService.getTemplates();
    setTemplates(templatesData?.data);
    setTemplateFilter(templatesData?.data);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
    localStorage.setItem('pathname', props?.location?.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const closeTemplateDialog = () => {
    setIsDialogOpen(false);
    setDialogData(undefined);
  };
  const onTemplateCreate = (state, errorMessage) => {
    if (state === 'success') {
      setShowSuccessToast(true);
      setSuccessToastText('Role saved successfully');
      setIsDialogOpen(false);
      setDialogData(undefined);
    } else {
      setShowErrorToast(true);
      setErrorToastText(errorMessage);
    }
  };

  const closeSuccessToast = () => {
    setShowSuccessToast(false);
    setSuccessToastText('');
  };

  const closeErrorToast = () => {
    setShowErrorToast(false);
    setErrorToastText('');
  };

  const headerContent = (
    <div
      className="DetailsAuditSideHeader"
      style={{
        display: 'flex',
        alignItems: 'center',
        placeContent: 'center flex-start',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }}
    >
      <div
        className="sideRoleName"
        style={{
          font: '500 20px/32px',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
      >
        {sideData?.name}
      </div>
    </div>
  );
  const titleBarPrimary = toggleMode => (
    <>
      <IconButton
        style={{ color: 'inherit' }}
        onClick={() => {
          toggleMode(true);
        }}
      >
        <SearchIcon />
      </IconButton>
    </>
  );
  const searchBarCommandPlaceholder = (
    <Fab
      style={{ position: 'relative', top: '2em' }}
      onClick={() => openTemplateDialog()}
      color="secondary"
      aria-label="Add template"
    >
      <AddIcon />
    </Fab>
  );
  const titleBar = (
    <SearchBarCotainer
      title="Template"
      backRoute="/"
      titleBarPrimary={titleBarPrimary}
      filter={onFilter}
      searchBarCommandPlaceholder={searchBarCommandPlaceholder}
    ></SearchBarCotainer>
  );

  const closeDeleteDialog = async data => {
    if (data && data.id) {
      try {
        await templateService.deleteTemplate(data.id);
        fetchData();
        setShowSuccessToast(true);
        setSuccessToastText('Template removed successfully!');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);
        onFilter(templateFilter);
      } catch {
        setShowErrorToast(true);
        setErrorToastText('Cannot remove template');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);
      }
    }
    setIsDeleteDialogOpen(false);
  };
  const confirmDelete = template => {
    const toDelete = { id: template.id, name: template.name, type: 'Template' };
    setToDelete(toDelete);
    setIsDeleteDialogOpen(true);
  };

  const templateInfoFunction = template => {
    const questions = template.TemplateQuestions.map(template => ({
      questionId: template?.Question.id,
      questionType: template?.Question?.type,
      questionOptions: template?.Question?.QuestionOptions,
      questionDescription: template?.Question?.description
    }));
    const templateInfo = { id: template.id, name: template.name, questions };

    setTemplateInformation(templateInfo);
    setIsTemplateInfoDialogOpen(true);
  };

  const closeTemplateInfoDialog = async () => {
    setIsTemplateInfoDialogOpen(false);
  };

  const openSideContent = async template => {
    let audits = [
      {
        user: template.createdBy,
        time: template.createdAt,
        action: 'Create'
      }
    ];
    if (template.updatedBy) {
      audits.push({
        user: template.updatedBy,
        time: template.updatedAt,
        action: 'Update'
      });
    }
    setAudits(audits);
    setSideData(template);
  };
  const sideContent = closeDrawer => (
    <DetailsAuditSideContainer
      headerContent={headerContent}
      audit={audits}
      closeDrawer={closeDrawer}
    />
  );
  const drawerWidth = 350;
  return (
    <LayoutContainer titleBar={titleBar} sideContent={sideContent} drawerWidth={drawerWidth}>
      <div>
        <TemplateList
          templates={templateFilter}
          openSideContent={openSideContent}
          isDialogOpen={isDialogOpen}
          closeTemplateDialog={closeTemplateDialog}
          openTemplateDialog={openTemplateDialog}
          onTemplateCreate={onTemplateCreate}
          dialogData={dialogData}
          isLoading={isLoading}
          errorToastText={errorToastText}
          closeErrorToast={closeErrorToast}
          showErrorToast={showErrorToast}
          successToastText={successToastText}
          closeSuccessToast={closeSuccessToast}
          showSuccessToast={showSuccessToast}
          toDelete={toDelete}
          confirmDelete={confirmDelete}
          closeDeleteDialog={closeDeleteDialog}
          isDeleteDialogOpen={isDeleteDialogOpen}
          templateInfoFunction={templateInfoFunction}
          closeTemplateInfoDialog={closeTemplateInfoDialog}
          isTemplateInfoDialogOpen={isTemplateInfoDialogOpen}
          templateInformation={templateInformation}
          fetchData={fetchData}
        />
      </div>
    </LayoutContainer>
  );
};

export default TemplateListContainer;
