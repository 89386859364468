import { axiosInstances } from '../../../utilities/axios/Instances/axios.instances';

const getEmployeeCertificates = id => {
  return axiosInstances.withAuthToken().get(`/employees/${id}/certificates`);
};
const getEmployeeCertificateById = (empId, certificateId) => {
  return axiosInstances.withAuthToken().get(`/employees/${empId}/certificates/${certificateId}`);
};
const addEmployeeCertificates = (id, data) => {
  return axiosInstances.withAuthToken().post(`/employees/${id}/certificates`, data);
};
const updateEmployeeCertificates = (empId, certificateId, data) => {
  return axiosInstances
    .withAuthToken()
    .patch(`/employees/${empId}/certificates/${certificateId}`, data);
};
const deleteEmployeeCertificates = (empId, certificateId) => {
  return axiosInstances.withAuthToken().delete(`/employees/${empId}/certificates/${certificateId}`);
};

export const CertificatesService = {
  getEmployeeCertificates,
  addEmployeeCertificates,
  getEmployeeCertificateById,
  deleteEmployeeCertificates,
  updateEmployeeCertificates
};
