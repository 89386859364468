import EmptyState from 'modules/shared/components/_states/EmptyState/EmptyState';
import EmptyCertificates from '../../../../assets/img/app_states/empty/certificate.png';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AddIconBox from '@material-ui/icons/AddBox';
import Dialog from '@material-ui/core/Dialog';
import CertificateInfoDialogContainer from './CertificateInfoDialog/CertificateInfoDialogContainer';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import ConfirmDialogComponent from 'modules/shared/components/confirm-dialog/ConfirmDialogComponent';

const EmployeeCertificates = props => {
  const hasCertificates = props?.certificates?.length > 0;

  return (
    <>
      <div className="employeeCertificates">
        <div className="titleWithBtn">
          <h3 className="sectionName">My Certificates</h3>
          <AddIconBox
            onClick={() => {
              props?.openCertificatesDialog();
            }}
            fontSize="medium"
            color="primary"
          />
        </div>
        <div className="certificatesWrapper">
          {hasCertificates ? (
            <div className="certificates">
              {props?.certificates?.map(certificate => (
                <div className="certificate" key={certificate?.id}>
                  <div className="certificateInfo">
                    <div
                      className="nameAndIcon"
                      onDoubleClick={() => {
                        props.openCertificateHandler(props?.employeeId, certificate?.id);
                      }}
                    >
                      <span className="certificateName">{certificate?.name}</span>
                      <a className="certificateURL" href={certificate?.url} target="blank">
                        <OpenInNewIcon />
                      </a>
                    </div>
                    <span className="certificateDate">{certificate?.certificationDate}</span>
                  </div>
                  <div className="certificateActions">
                    <EditIcon
                      fontSize="small"
                      onClick={() => props.openCertificatesDialog(certificate)}
                    />
                    <DeleteIcon fontSize="small" onClick={() => props.confirmDelete(certificate)} />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <EmptyState
              stateImage={
                <div>
                  <img src={EmptyCertificates} alt="" width={50} />
                </div>
              }
              stateText={
                <div className="empty-text-section">
                  <div
                    style={{ marginBottom: '0.75em', font: '400 15px/24px' }}
                    className="empty-text-message"
                  >
                    You haven't added any certificates yet.
                  </div>
                  <div className="empty-text-action" style={{ textAlign: 'center' }}>
                    <Button
                      style={{
                        position: 'static',
                        textTransform: 'unset',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '16px'
                      }}
                      onClick={() => {
                        props?.openCertificatesDialog();
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <AddIcon /> Add certificates
                    </Button>
                  </div>
                </div>
              }
            />
          )}
        </div>
      </div>
      <Dialog
        fullWidth={false}
        maxWidth="md"
        open={props?.isDialogOpen}
        onClose={() => props?.closeCertificatesDialog()}
        aria-labelledby="form-dialog-title"
        title="Add Certificate"
      >
        <CertificateInfoDialogContainer
          employeeId={props?.employeeId}
          certificates={props?.certificates}
          setCertificates={props?.setCertificates}
          certificateData={props?.certificateData}
          setCertificateData={props?.setCertificateData}
          closeCertificatesDialog={props?.closeCertificatesDialog}
          onCertificateCreate={props?.onCertificateCreate}
        />
      </Dialog>
      <Dialog open={props.isDeleteDialogOpen} onBackdropClick={props.closeDeleteDialog}>
        <ConfirmDialogComponent data={props.toDelete} close={props.closeDeleteDialog} />
      </Dialog>
      <Snackbar
        open={props.showSuccessToast}
        autoHideDuration={1000}
        onClose={props.closeSuccessToast}
      >
        <Alert onClose={props.closeSuccessToast} severity={'success'}>
          {props.successToastText}
        </Alert>
      </Snackbar>

      <Snackbar open={props.showErrorToast} autoHideDuration={1000} onClose={props.closeErrorToast}>
        <Alert onClose={props.closeErrorToast} severity={'error'}>
          {props.errorToastText}
        </Alert>
      </Snackbar>
    </>
  );
};
export default EmployeeCertificates;
