import Button from '@material-ui/core/Button';
import './ConfirmDialogComponent.scss';
const ConfirmDialogComponent = props => {
  return (
    <div className="confirmDeleteDialog">
      <div className="confirmDialogTitle">Change activity of the question</div>
      <div className="confirmDialogContent">
        <div>
          <b className="truncate">{props.data?.description || 'this item'}</b>
        </div>
        <div>
          Are you sure to change the status of question to be
          {props.data?.active ? 'inactive' : 'active'} ?
        </div>
      </div>
      <div className="confermDialogActions">
        <Button onClick={props.close} focusRipple={true}>
          No
        </Button>
        <Button
          onClick={() => props.close({ ...props.data, active: !props.data.active })}
          color="primary"
        >
          Yes
        </Button>
      </div>
    </div>
  );
};
export default ConfirmDialogComponent;
