import { useEffect, useState } from 'react';
import { questionsService } from '../../services/question';
import QuestionChipList from './QuestionChipList';
function TemplateChipListContainer(props) {
  const [questions, setQuestions] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchQuestionsData = async () => {
      const questionActive = true;
      const allQuestionsData = await questionsService.getQuestions(questionActive);
      const filteredAllQuestions = allQuestionsData.filter(
        emp => !props?.excluded?.includes(+emp.id)
      );
      const filteredQuestions = filteredAllQuestions?.filter(emp =>
        props.questions?.includes(+emp.id)
      );
      setAllQuestions(filteredAllQuestions);
      setQuestions(filteredQuestions);
      setIsLoading(false);
    };
    fetchQuestionsData();
  }, [props.excluded, props.questions]);

  return (
    <QuestionChipList
      name={props?.name}
      placeholder={'Questions'}
      showPrefix={props.showPrefix}
      maxCount={props.maxCount}
      allQuestions={allQuestions}
      isLoading={isLoading}
      questions={questions}
      handleQuestionsChange={props.handleQuestionsChange}
      multiple={true}
      fullWidth={true}
      required
      onBlur={props?.onBlur}
      error={props?.error}
    />
  );
}
TemplateChipListContainer.defaultProps = {
  placeholder: 'Questions',
  showPrefix: true,
  maxCount: 0,
  excluded: [],
  questions: [],
  selectable: false,
  removable: false,
  multiple: true,
  fullWidth: false
};

export default TemplateChipListContainer;
