import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import { TextareaAutosize, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import Menu from '../../../shared/components/_controls/Menu/Menu';
import EmptyState from 'modules/shared/components/_states/EmptyState/EmptyState';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';
import ConfirmDialogComponent from '../confirm-dialog-component/ConfirmDialogComponent';
import QuestionDialogContainer from '../question-dialog/QuestionDialogContainer';
import QuestionInfoDialog from '../question-info-dialog/QuestionInfoDialog';
import EmptyTemplateImg from 'assets/img/app_states/empty/No-History.png';
import './QuestionList.scss';

function QuestionList(props) {
  return (
    <div id="QuestionList">
      {!props.isLoading ? (
        props.questions.length > 0 ? (
          <div className="questionsContainerList">
            {props.questions.map((question, idx) => (
              <div key={idx} className="questionContainerList">
                <div className="questionListHeader">
                  <div className="questionNumber">Q{idx + 1}</div>
                  <div className="menuContainer">
                    <Menu>
                      <div
                        className="CustommenuItem"
                        onClick={() => props.openQuestionDialog(question)}
                      >
                        <EditIcon />
                        <span style={{ marginLeft: '16px' }}>Edit</span>
                      </div>
                      <div
                        className="CustommenuItem"
                        onClick={() => props.confirmInactiveQuestion(question)}
                      >
                        {question.active === true ? <ToggleOffIcon /> : <ToggleOnIcon />}
                        <span style={{ marginLeft: '16px' }}>
                          {question.active === true ? 'inactive' : 'action'}
                        </span>
                      </div>
                    </Menu>
                  </div>
                </div>
                <div className="questionInformation">
                  <div className="questionDescription">{question.description}</div>
                  {question.type === 'rating' && (
                    <div className="questionRatingType">
                      <FormControl disabled={true}>
                        <RadioGroup
                          className="questionRadioGroup"
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name={`question-${question?.templateQuestionsId}`}
                        >
                          {question?.QuestionOptions?.map(({ value, name }) => (
                            <FormControlLabel
                              key={value}
                              value={name}
                              control={<Radio />}
                              label={name}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}
                  {question.type === 'text' && (
                    <div className="questionTextType">
                      <TextareaAutosize
                        aria-label="minimum height"
                        className="textareaText"
                        disabled={true}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <EmptyState
            stateImage={
              <div>
                <img src={EmptyTemplateImg} alt="emptyTemplate" />
              </div>
            }
            stateText={
              <div className="empty-text-section">
                <div
                  style={{ marginBottom: '0.75em', font: '400 15px/24px "Poppins", sans-serif' }}
                  className="empty-text-message"
                >
                  No questions found.
                </div>
              </div>
            }
          />
        )
      ) : (
        <LoadingState />
      )}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={props.isDialogOpen}
        onBackdropClick={props.closeQuestionDialog}
        aria-labelledby="form-dialog-title"
      >
        <QuestionDialogContainer
          data={props.dialogData}
          closeDialog={props.closeQuestionDialog}
          onQuestionCreate={props.onQuestionCreate}
          fetchData={props.fetchData}
        />
      </Dialog>

      <Dialog open={props.isDeleteDialogOpen} onBackdropClick={props.closeDeleteDialog}>
        <ConfirmDialogComponent data={props.toDelete} close={props.closeDeleteDialog} />
      </Dialog>
      <Dialog
        maxWidth="md"
        open={props.isQuestionInfoDialogOpen}
        onBackdropClick={props.closeQuestionInfoDialog}
      >
        <QuestionInfoDialog
          question={props?.questionInformation}
          close={props.closeQuestionInfoDialog}
        />
      </Dialog>

      <Snackbar
        open={props.showSuccessToast}
        autoHideDuration={1000}
        onClose={props.closeSuccessToast}
      >
        <Alert onClose={props.closeSuccessToast} severity={'success'}>
          {props.successToastText}
        </Alert>
      </Snackbar>

      <Snackbar open={props.showErrorToast} autoHideDuration={1000} onClose={props.closeErrorToast}>
        <Alert onClose={props.closeErrorToast} severity={'error'}>
          {props.errorToastText}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default QuestionList;
