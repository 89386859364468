import { axiosInstances } from 'utilities/axios/Instances/axios.instances';
const addQuestion = data => {
  return axiosInstances.withAuthToken().post('/questions', data);
};
const updateQuestion = (id, data) => {
  return axiosInstances.withAuthToken().patch(`/questions/${id}`, data);
};

const deleteQuestion = id => {
  return axiosInstances.withAuthToken().delete(`/questions/${id}`);
};

const getAllQuestions = () => {
  return axiosInstances.withAuthToken().get('/questions');
};
export const questionService = {
  getAllQuestions,
  addQuestion,
  updateQuestion,
  deleteQuestion
};
