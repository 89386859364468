import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import './CertificateInfoDialog.scss';
import Calendar from 'modules/shared/components/_employee-views/Calendar/DatePicker/Calendar';

const CertificateInfoDialog = props => {
  return (
    <div className="certificateDialog">
      <h3>Add Certificate</h3>
      <div className="fieldWrapper">
        {' '}
        <TextField
          onChange={e => {
            props.handleChange(e);
          }}
          value={props?.certificateData?.name}
          name="name"
          required
          margin="dense"
          id="Name"
          label="Name"
          type="text"
          style={{ flex: '1 1 0%' }}
          onBlur={props?.handleBlur}
          {...props?.certificateFieldsProps?.name}
        />
        <TextField
          onChange={e => {
            props.handleChange(e);
          }}
          value={props?.certificateData?.url}
          name="url"
          margin="dense"
          id="url"
          label="url"
          type="text"
          style={{ flex: '1 1 0%' }}
          onBlur={props?.handleBlur}
          required
          {...props?.certificateFieldsProps?.url}
        />
      </div>
      <Calendar
        handleChange={e => {
          props.handleChange(e);
        }}
        name="certificationDate"
        date={props?.certificateData?.certificationDate || undefined}
        placeholder="Certification Date"
        fullWidth={true}
        onBlur={props?.handleBlur}
        required
        max={Date.now()}
        {...props?.certificateFieldsProps?.certificationDate}
      />
      <div className="actionsWrapper">
        <Button
          onClick={() => {
            props?.closeCertificatesDialog();
          }}
        >
          cancel
        </Button>
        <Button
          color="primary"
          onClick={() => {
            props?.submitHandler();
          }}
          disabled={!props?.isValid}
        >
          Add
        </Button>
      </div>
    </div>
  );
};
export default CertificateInfoDialog;
