import { useState } from 'react';
import CertificateInfoDialog from './CertificateInfoDialog';
import { CertificatesService } from 'modules/shared/services/certificates-service';

const CertificateInfoDialogContainer = props => {
  const [certificateFieldsProps, setCertificateFieldsProps] = useState({
    name: { error: false, helperText: '' },
    url: { error: false, helperText: '' },
    certificationDate: { error: false, helperText: '' }
  });
  const [isValid, setIsValid] = useState(!!props?.certificateData);

  const handleBlur = e => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    if (value?.trim() === '') {
      setCertificateFieldsProps({
        ...certificateFieldsProps,
        [name]: { error: true, helperText: 'Field must not be empty' }
      });
    } else {
      setCertificateFieldsProps({
        name: { error: false, helperText: '' }
      });
    }
  };
  const submitHandler = async () => {
    const certificateData = {
      id: props?.certificateData?.id,
      name: props?.certificateData?.name?.trim(),
      url: props?.certificateData?.url,
      certificationDate: props?.certificateData?.certificationDate
    };
    if (certificateData?.id) {
      try {
        const { id: certificateId, ...rest } = certificateData;

        await CertificatesService.updateEmployeeCertificates(
          props?.employeeId,
          certificateId,
          rest
        );
        props.onCertificateCreate('success');
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        props.onCertificateCreate('fail', errorMessage);
      }
    } else {
      try {
        await CertificatesService.addEmployeeCertificates(props?.employeeId, certificateData);
        props.onCertificateCreate('success');
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        props.onCertificateCreate('fail', errorMessage);
      }
    }
  };
  const handleChange = e => {
    const updatedCertificate = {
      ...(props?.certificateData || {}),
      [e?.target?.name || 'certificationDate']: !e?.target?.name ? e : e?.target?.value
    };

    let isValid = false;
    if (checkValidity(updatedCertificate)) {
      isValid = true;
    }
    setIsValid(isValid);
    props?.setCertificateData(updatedCertificate);
  };
  const checkValidity = certificate => {
    console.log('🚀 ~ checkValidity ~ certificate:', certificate);
    const { name, url, certificationDate } = certificate;
    return name && url && certificationDate ? true : false;
  };
  return (
    <CertificateInfoDialog
      closeCertificatesDialog={props?.closeCertificatesDialog}
      handleBlur={handleBlur}
      certificateFieldsProps={certificateFieldsProps}
      certificateData={props?.certificateData}
      submitHandler={submitHandler}
      handleChange={handleChange}
      isValid={isValid}
    />
  );
};
export default CertificateInfoDialogContainer;
